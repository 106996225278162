// @flow
import React, { memo, useEffect, useState, type Element } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Foot = styled.footer`
  ${({ sidebar }) =>
    sidebar &&
    `
    position:fixed;
    width:calc(100% - 300px);
    bottom:0;
    left:300px;
    background:var(--backgroundDefault);
    z-index:999;
  `};
  padding: calc(var(--spacing) * 2) 0;
  text-align: center;
  color: var(--colorDefault);
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
  }

  & > span {
    font-size: 12px;
  }
`;

type Props = Object;

const domainsCopyrights = {
  hsn: 'HIPAA Secure Now! Copyright',
  pii: 'PII Protect Copyright',
  phi: 'PHI Protect Copyright',
  bsn: 'Breach Secure Now! Copyright',
};

const Footer = (props: Props): Element<*> => {
  const [brandName, setBrandName] = useState(localStorage.getItem('brandName') || 'pii');
  const hostname = window.location.hostname;
  const isLocalhost = hostname === 'localhost';

  useEffect(() => {
    if (!isLocalhost) return;
    const handleBrandNameChange = () => {
      const brandName = localStorage.getItem('brandName');
      if (brandName) {
        setBrandName(brandName);
      }
    };
    window.addEventListener('brand-name-change', handleBrandNameChange);
    return () => {
      window.removeEventListener('brand-name-change', handleBrandNameChange);
    };
  }, [isLocalhost]);

  const currentDomain = isLocalhost
    ? brandName
    : hostname.includes('hipaasecurenow.com')
    ? 'hsn'
    : hostname.includes('phi-protect.com')
    ? 'phi'
    : hostname.includes('breachsecurenow.com')
    ? 'bsn'
    : 'pii';
  const copyright = domainsCopyrights[currentDomain];
  return (
    <Foot {...props} className="mui-fixed">
      <Typography variant="caption">
        {copyright} &#169; {new Date().getFullYear()}
      </Typography>
    </Foot>
  );
};

export default memo<Props>(Footer);
