var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { alpha } from '@mui/material';
import { GridLogicOperator } from '@mui/x-data-grid-pro';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import DataGridFilterPanel from '../../components/DataGrid/DataGridFilterPanel';
import DataGridPagination from '../../components/DataGrid/DataGridPagination';
import IconButton from '../../components/IconButton';
import Select from '../../components/Select';
import Switch from '../../components/Switch';
import TextField from '../../components/TextField';
import { AddIcon, ArrowDownloadIcon, ArrowSyncIcon, BulletMenuHorizontalIcon, CloseIcon, ClosedEyeIcon, DownArrowIcon, FilterIcon, PinIcon, SearchIcon, TrashIcon, UpArrowIcon, } from '../../icons';
var DataGrid = {
    MuiDataGrid: {
        defaultProps: {
            getRowHeight: function () { return 'auto'; },
            disableAggregation: true,
            disableRowGrouping: true,
            slots: {
                filterPanel: DataGridFilterPanel,
                baseSwitch: Switch,
                baseTextField: TextField,
                baseCheckbox: Checkbox,
                baseSelect: Select,
                baseButton: Button,
                baseIconButton: IconButton,
                columnSortedAscendingIcon: UpArrowIcon,
                columnSortedDescendingIcon: DownArrowIcon,
                columnFilteredIcon: FilterIcon,
                filterPanelDeleteIcon: CloseIcon,
                loadIcon: ArrowSyncIcon,
                quickFilterIcon: SearchIcon,
                quickFilterClearIcon: CloseIcon,
                filterPanelRemoveAllIcon: TrashIcon,
                filterPanelAddIcon: AddIcon,
                exportIcon: ArrowDownloadIcon,
                columnMenuIcon: BulletMenuHorizontalIcon,
                columnMenuFilterIcon: FilterIcon,
                openFilterButtonIcon: FilterIcon,
                columnMenuPinLeftIcon: PinIcon,
                columnMenuPinRightIcon: function () { return _jsx(PinIcon, { sx: { fontSize: 18, transform: 'rotateY(180deg)' } }); },
                columnMenuHideIcon: ClosedEyeIcon,
                moreActionsIcon: BulletMenuHorizontalIcon,
                columnMenuSortAscendingIcon: UpArrowIcon,
                columnMenuSortDescendingIcon: DownArrowIcon,
            },
            slotProps: {
                baseTextField: {
                    size: 'small',
                },
                baseSelect: {
                    native: false,
                    variant: 'outlined',
                    size: 'small',
                    placeholder: 'Filter value',
                },
                pagination: {
                    ActionsComponent: DataGridPagination,
                },
                filterPanel: {
                    logicOperators: [GridLogicOperator.And],
                    filterFormProps: {
                        logicOperatorInputProps: { sx: { display: 'none' } },
                        valueInputProps: {
                            InputComponentProps: {
                                variant: 'outlined',
                            },
                        },
                    },
                },
                panel: {
                    sx: {
                        '& .MuiDataGrid-panelWrapper': {
                            maxWidth: 'calc(100vw - 4rem)',
                        },
                    },
                },
            },
            rowSelection: false,
            pageSizeOptions: [25, 50, 100, 200],
            rowHeight: 60,
            columnHeaderHeight: 60,
            paginationMode: 'server',
            sortingMode: 'server',
            filterMode: 'server',
            disableColumnPinning: true,
            disableColumnSelector: true,
        },
        styleOverrides: {
            root: function (_a) {
                var theme = _a.theme;
                return ({
                    '--unstable_DataGrid-overlayBackground': alpha(theme.palette.background.paper, 0.7),
                });
            },
            withBorderColor: function (_a) {
                var theme = _a.theme;
                return ({
                    borderColor: theme.palette.divider,
                });
            },
            sortIcon: {
                fontSize: 16,
            },
            filterIcon: {
                fontSize: 15,
            },
            columnHeader: function (_a) {
                var theme = _a.theme;
                return ({
                    padding: theme.spacing(0, 2.5),
                    '&:focus-within, &:focus': {
                        outline: 'none',
                    },
                });
            },
            columnHeaderCheckbox: {
                padding: 0,
            },
            columnHeaderTitle: function (_a) {
                var theme = _a.theme;
                return (__assign(__assign({}, theme.typography.button), { textTransform: 'initial', whiteSpace: 'normal' }));
            },
            cell: function (_a) {
                var theme = _a.theme;
                return ({
                    padding: theme.spacing(2.5),
                    '&:focus-within, &:focus': {
                        outline: 'none',
                    },
                });
            },
            iconButtonContainer: {
                '&:has(.MuiDataGrid-sortIcon)': {
                    marginLeft: 'auto',
                    '.MuiBadge-badge': {
                        right: 'auto',
                        fontSize: 12,
                    },
                },
            },
            filterForm: function (_a) {
                var theme = _a.theme;
                return ({
                    padding: theme.spacing(2.5),
                    alignItems: 'center',
                });
            },
            filterFormColumnInput: function (_a) {
                var theme = _a.theme;
                return ({
                    marginRight: theme.spacing(1),
                    minWidth: 150,
                    '.MuiInputBase-root': {
                        height: 40,
                    },
                    '.MuiFormLabel-root': {
                        transform: 'translate(13px, -7px) scale(0.75)',
                    },
                });
            },
            menuIcon: {
                '.MuiSvgIcon-root': {
                    fontSize: 18,
                },
            },
            menu: {
                '.MuiSvgIcon-root': {
                    fontSize: 18,
                },
            },
            filterFormOperatorInput: function (_a) {
                var theme = _a.theme;
                return ({
                    marginRight: theme.spacing(1),
                    minWidth: 120,
                    display: 'none',
                    '.MuiInputBase-root': {
                        height: 40,
                    },
                    '.MuiFormLabel-root': {
                        transform: 'translate(14px, -7px) scale(0.75)',
                    },
                });
            },
            filterFormValueInput: {
                width: '100%',
                '.MuiInputBase-root': {
                    minWidth: 190,
                    minHeight: 40,
                },
                '.MuiFormLabel-root': {
                    transform: 'translate(13px, -7px) scale(0.75)',
                },
            },
            filterFormDeleteIcon: function (_a) {
                var theme = _a.theme;
                return ({
                    marginRight: theme.spacing(1.25),
                    '.MuiSvgIcon-root': {
                        fontSize: 18,
                    },
                });
            },
            toolbarContainer: {
                '.MuiButton-startIcon .MuiBadge-root  .MuiSvgIcon-root': {
                    fontSize: 18,
                },
            },
            panelFooter: function (_a) {
                var theme = _a.theme;
                return ({
                    padding: theme.spacing(2.5),
                });
            },
            row: function (_a) {
                var theme = _a.theme;
                return ({
                    '&.Mui-selected, &.Mui-selected:hover': {
                        background: alpha(theme.palette.info.main, 0.2),
                        color: theme.palette.info.main,
                    },
                });
            },
            cellContent: function (_a) {
                var theme = _a.theme;
                return (__assign({}, theme.typography.body2));
            },
            footerContainer: {
                minHeight: 70,
            },
            pinnedColumnHeaders: function (_a) {
                var theme = _a.theme;
                return ({
                    background: theme.palette.background.paper,
                });
            },
            pinnedColumns: function (_a) {
                var theme = _a.theme;
                return ({
                    background: theme.palette.background.paper,
                });
            },
            'pinnedColumnHeaders--left': function (_a) {
                var theme = _a.theme;
                return ({
                    borderRight: "1px solid ".concat(theme.palette.divider),
                });
            },
            'pinnedColumns--left': function (_a) {
                var theme = _a.theme;
                return ({
                    borderRight: "1px solid ".concat(theme.palette.divider),
                });
            },
            'pinnedColumnHeaders--right': function (_a) {
                var theme = _a.theme;
                return ({
                    borderLeft: "1px solid ".concat(theme.palette.divider),
                });
            },
            'pinnedColumns--right': function (_a) {
                var theme = _a.theme;
                return ({
                    borderLeft: "1px solid ".concat(theme.palette.divider),
                });
            },
        },
    },
};
export default DataGrid;
