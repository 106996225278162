var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { styled } from '../../theme';
import FormControlLabel from '../FormControlLabel';
import Switch from '../Switch';
var StyledFormControlLabel = styled(FormControlLabel)(function (_a) {
    var theme = _a.theme;
    return ({
        gap: theme.spacing(1.25),
    });
});
var SwitchInput = forwardRef(function (props, ref) {
    var _a = props.SwitchProps, SwitchProps = _a === void 0 ? {} : _a, _b = props.slotProps, slotProps = _b === void 0 ? {} : _b, other = __rest(props, ["SwitchProps", "slotProps"]);
    return (_jsx(StyledFormControlLabel, __assign({}, other, { ref: ref, slotProps: __assign(__assign({}, slotProps), { typography: __assign({ variant: 'button' }, slotProps === null || slotProps === void 0 ? void 0 : slotProps.typography) }), control: _jsx(Switch, __assign({}, SwitchProps, { ref: SwitchProps.ref })) })));
});
export default SwitchInput;
