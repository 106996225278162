var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link as MuiLink } from '@mui/material';
import { forwardRef } from 'react';
import CircularProgress from '../CircularProgress';
import Box from '../Box';
var Link = forwardRef(function (props, ref) {
    var startIcon = props.startIcon, endIcon = props.endIcon, loading = props.loading, _a = props.loadingSize, loadingSize = _a === void 0 ? 16 : _a, children = props.children, _b = props.sx, sx = _b === void 0 ? {} : _b, hoverColor = props.hoverColor, other = __rest(props, ["startIcon", "endIcon", "loading", "loadingSize", "children", "sx", "hoverColor"]);
    return (_jsxs(MuiLink, __assign({ ref: ref, sx: typeof sx === 'function'
            ? function (theme) { return ({ '&:hover': __assign({ color: hoverColor }, sx(theme)) }); }
            : __assign({ '&:hover': { color: hoverColor } }, sx) }, other, { children: [loading && _jsx(CircularProgress, { className: 'MuiLink-loadingIndicator', color: 'inherit', size: loadingSize }), startIcon && (_jsx(Box, __assign({ component: 'span', className: 'MuiLink-startIcon' }, { children: startIcon }))), children, endIcon && (_jsx(Box, __assign({ component: 'span', className: 'MuiLink-endIcon' }, { children: endIcon })))] })));
});
export default Link;
