var Switch = {
    MuiSwitch: {
        styleOverrides: {
            root: function (_a) {
                var theme = _a.theme;
                return ({
                    width: 45,
                    height: 25,
                    padding: 0,
                    borderRadius: theme.shape.borderRadius * 5,
                });
            },
            switchBase: function (_a) {
                var theme = _a.theme;
                return ({
                    padding: 0,
                    margin: theme.spacing(0.5),
                    color: theme.palette.common.white,
                    '&.Mui-checked': {
                        color: theme.palette.common.white,
                        '+.MuiSwitch-track': {
                            opacity: 1,
                        },
                    },
                });
            },
            thumb: function (_a) {
                var theme = _a.theme;
                return ({
                    height: 17,
                    width: 17,
                    boxShadow: theme.shadows[0],
                });
            },
            track: function (_a) {
                var theme = _a.theme;
                return ({
                    backgroundColor: theme.palette.grey[300],
                    opacity: 1,
                });
            },
        },
    },
};
export default Switch;
