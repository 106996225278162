import { jsx as _jsx } from "react/jsx-runtime";
import { ArrowDownIcon, CloseIcon } from '../../icons';
/**
 * Retrieves the padding block size for an input element based on its size.
 * @param {'small' | 'medium' | 'x-small' | 'large'} size The size of the input element.
 * @returns {number} The padding block size of the input element.
 */
var getInputPaddingBlock = function (size) {
    switch (size) {
        case 'x-small':
            return 0;
        case 'small':
            return 0.5;
        case 'medium':
            return 1;
        case 'large':
            return 1.5;
        default:
            return 0;
    }
};
/**
 * Calculates the translateY value for the label of a text field based on its size.
 * @param {'small' | 'medium' | 'x-small' | 'large'} size - The size of the text field.
 * @returns {number} The translateY value for the label.
 */
var getTextFieldLabelTranslateY = function (size) {
    switch (size) {
        case 'x-small':
            return 5;
        case 'small':
            return 9;
        case 'medium':
            return 13;
        case 'large':
            return 17;
        default:
            return 0;
    }
};
var Autocomplete = {
    MuiAutocomplete: {
        defaultProps: {
            size: 'small',
            popupIcon: _jsx(ArrowDownIcon, {}),
            clearIcon: _jsx(CloseIcon, {}),
            disableCloseOnSelect: true,
        },
        styleOverrides: {
            root: function (_a) {
                var _b = _a.ownerState.size, size = _b === void 0 ? 'small' : _b, theme = _a.theme;
                return ({
                    '.MuiOutlinedInput-root .MuiOutlinedInput-input.MuiAutocomplete-input': {
                        paddingBlock: theme.spacing(getInputPaddingBlock(size)),
                    },
                    '.MuiFormControl-root.MuiTextField-root .MuiFormLabel-root': {
                        '&[data-shrink=false]': {
                            transform: "translate(12px, \n              ".concat(getTextFieldLabelTranslateY(size), "px)"),
                        },
                    },
                });
            },
            clearIndicator: function (_a) {
                var theme = _a.theme, _b = _a.ownerState.size, size = _b === void 0 ? 'small' : _b;
                return ({
                    padding: theme.spacing(0.5),
                    '.MuiSvgIcon-root': {
                        fontSize: size === 'large' ? 16 : 14,
                    },
                });
            },
            popper: function (_a) {
                var theme = _a.theme;
                return ({
                    paddingTop: theme.spacing(1),
                });
            },
            popupIndicator: function (_a) {
                var theme = _a.theme, _b = _a.ownerState.size, size = _b === void 0 ? 'small' : _b;
                return ({
                    padding: theme.spacing(0.5),
                    marginLeft: theme.spacing(0.5),
                    '.MuiSvgIcon-root': {
                        fontSize: size === 'large' ? 16 : 14,
                    },
                });
            },
            endAdornment: {
                top: 'auto',
            },
        },
    },
};
export default Autocomplete;
