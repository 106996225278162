var Link = {
    MuiLink: {
        defaultProps: { variant: 'link2', color: 'text.primary', textAlign: 'initial' },
        styleOverrides: {
            root: {
                textDecorationColor: 'inherit',
            },
            button: function (_a) {
                var theme = _a.theme;
                return ({
                    display: 'inline-flex',
                    alignItems: 'center',
                    '.MuiLink-startIcon, .MuiLink-loadingIndicator': {
                        marginRight: theme.spacing(1),
                    },
                    '.MuiLink-endIcon': {
                        marginLeft: theme.spacing(1),
                    },
                    '.MuiLink-startIcon, .MuiLink-endIcon': {
                        display: 'inherit',
                        '.MuiSvgIcon-root': {
                            fontSize: 20,
                        },
                    },
                    '&:disabled': {
                        color: theme.palette.grey[theme.palette.mode === 'dark' ? 600 : 300],
                        pointerEvents: 'none',
                    },
                });
            },
        },
    },
};
export default Link;
