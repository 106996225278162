var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { forwardRef } from 'react';
import RDZDropzone from 'react-dropzone';
import { Box, Stack, Typography } from '..';
import { CloudUploadIcon } from '../../icons';
var StyledBox = styled(Box)(function (_a) {
    var theme = _a.theme;
    var borderColor = theme.palette.info.main;
    return {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(4, 3),
        cursor: 'pointer',
        backgroundImage: "repeating-linear-gradient(180deg, ".concat(borderColor, ", ").concat(borderColor, " 8px, transparent 8px, transparent 12px, ").concat(borderColor, " 12px),\n      repeating-linear-gradient(270deg, ").concat(borderColor, ", ").concat(borderColor, " 8px, transparent 8px, transparent 12px, ").concat(borderColor, " 12px),\n      repeating-linear-gradient(0deg, ").concat(borderColor, ", ").concat(borderColor, " 8px, transparent 8px, transparent 12px, ").concat(borderColor, " 12px),\n      repeating-linear-gradient(90deg, ").concat(borderColor, ", ").concat(borderColor, " 8px, transparent 8px, transparent 12px, ").concat(borderColor, " 12px)"),
        backgroundSize: '2px 100%, 100% 2px, 2px 100% , 100% 2px',
        backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
        backgroundRepeat: 'no-repeat',
    };
});
var Dropzone = forwardRef(function (props, ref) {
    var RootProps = props.RootProps, _a = props.title, title = _a === void 0 ? 'Attachments' : _a, _b = props.subtitle, subtitle = _b === void 0 ? '' : _b, other = __rest(props, ["RootProps", "title", "subtitle"]);
    return (_jsx(RDZDropzone, __assign({}, other, { ref: ref }, { children: function (_a) {
            var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps;
            return (_jsxs(StyledBox, __assign({}, getRootProps(), RootProps, { children: [_jsx("input", __assign({}, getInputProps())), _jsxs(Stack, __assign({ spacing: 2, direction: 'row', alignItems: 'center' }, { children: [_jsx(CloudUploadIcon, { fontSize: 'large', color: 'info' }), _jsxs(Stack, { children: [_jsx(Typography, __assign({ variant: 'subtitle2', color: 'info.main' }, { children: title })), subtitle ? (typeof subtitle === 'string' ? (_jsx(Typography, __assign({ variant: 'subtitle2' }, { children: subtitle }))) : (subtitle)) : (_jsxs(Typography, __assign({ variant: 'subtitle2', fontWeight: function (theme) { return theme.typography.fontWeightRegular; } }, { children: ["Drag & drop here or\u00A0", _jsx(Typography, __assign({ borderBottom: 1, component: 'span', variant: 'subtitle2' }, { children: "browse" }))] })))] })] }))] })));
        } })));
});
export default Dropzone;
