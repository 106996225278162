var FormControlLabel = {
    MuiFormControlLabel: {
        styleOverrides: {
            root: function (_a) {
                var theme = _a.theme;
                return ({
                    gap: theme.spacing(0.25),
                });
            },
        },
    },
};
export default FormControlLabel;
