var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { GridToolbarQuickFilter as MuiGridToolbarQuickFilter, } from '@mui/x-data-grid-premium';
import { forwardRef } from 'react';
var StyledGridToolbarQuickFilter = styled(MuiGridToolbarQuickFilter, {
    shouldForwardProp: function (propName) { return propName !== 'iconPosition'; },
})(function (_a) {
    var iconPosition = _a.iconPosition, fullWidth = _a.fullWidth, theme = _a.theme;
    return (__assign(__assign({ paddingBottom: 0 }, (fullWidth && { width: '100%' })), { '.MuiSvgIcon-root': {
            fontSize: 18,
        }, '.MuiInputBase-root > .MuiSvgIcon-root': __assign({ marginInline: theme.spacing(0.5) }, (iconPosition === 'end' && { order: 1 })) }));
});
var GridToolbarQuickFilter = forwardRef(function (props, ref) {
    var _a = props.variant, variant = _a === void 0 ? 'outlined' : _a, other = __rest(props, ["variant"]);
    return _jsx(StyledGridToolbarQuickFilter, __assign({}, other, { variant: variant === 'filled' ? 'filled' : 'outlined', ref: ref }));
});
export default GridToolbarQuickFilter;
