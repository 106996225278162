import Accordion from './Accordion';
import AccordionActions from './AccordionActions';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';
import Alert from './Alert';
import Autocomplete from './Autocomplete';
import Button from './Button';
import ButtonBase from './ButtonBase';
import Card from './Card';
import CardActions from './CardActions';
import CardContent from './CardContent';
import CardHeader from './CardHeader';
import CardMedia from './CardMedia';
import Checkbox from './Checkbox';
import Chip from './Chip';
import CircularProgress from './CircularProgress';
import Collapse from './Collapse';
import DataGrid from './DataGrid';
import DateCalender from './DateCalender';
import DatePicker from './DatePicker';
import DayCalendar from './DayCalendar';
import Dialog from './Dialog';
import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import DialogContentText from './DialogContentText';
import DialogTitle from './DialogTitle';
import FilledInput from './FilledInput';
import FormControlLabel from './FormControlLabel';
import FormHelperText from './FormHelperText';
import FormLabel from './FormLabel';
import IconButton from './IconButton';
import InputAdornment from './InputAdornment';
import InputBase from './InputBase';
import InputLabel from './InputLabel';
import LinearProgress from './LinearProgress';
import Link from './Link';
import ListItem from './ListItem';
import ListItemAvatar from './ListItemAvatar';
import ListItemButton from './ListItemButton';
import ListItemIcon from './ListItemIcon';
import ListItemSecondaryAction from './ListItemSecondaryAction';
import ListItemText from './ListItemText';
import MenuItem from './MenuItem';
import OutlinedInput from './OutlinedInput';
import PaginationItem from './PaginationItem';
import Paper from './Paper';
import PickersArrowSwitcher from './PickersArrowSwitcher';
import PickersCalendarHeader from './PickersCalendarHeader';
import PickersDay from './PickersDay';
import PickersLayout from './PickersLayout';
import PickersYear from './PickersYear';
import Radio from './Radio';
import Rating from './Rating';
import Select from './Select';
import StepConnector from './StepConnector';
import StepIcon from './StepIcon';
import StepLabel from './StepLabel';
import Stepper from './Stepper';
import SvgIcon from './SvgIcon';
import Switch from './Switch';
import Tab from './Tab';
import TablePagination from './TablePagination';
import Tabs from './Tabs';
import TextField from './TextField';
import Toolbar from './Toolbar';
import Tooltip from './Tooltip';
import Typography from './Typography';
import YearCalendar from './YearCalendar';
import UseMediaQuery from './UseMediaQuery';
/**
 * Components custom configurations for style overrides, default props, and variants used across the theme context.
 *
 * @see [API documentation](https://mui.com/material-ui/customization/theme-components/)
 */
var components = Object.assign(Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Autocomplete, Button, ButtonBase, Card, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Chip, CircularProgress, Collapse, DataGrid, DateCalender, DatePicker, DayCalendar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FilledInput, FormControlLabel, FormHelperText, FormLabel, IconButton, InputAdornment, InputBase, InputLabel, LinearProgress, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, OutlinedInput, PaginationItem, Paper, PickersArrowSwitcher, PickersCalendarHeader, PickersDay, PickersLayout, PickersYear, Radio, Select, StepConnector, StepIcon, StepLabel, Stepper, SvgIcon, Switch, Tab, TablePagination, Tabs, TextField, Toolbar, Tooltip, Typography, Link, Rating, YearCalendar, UseMediaQuery);
export default components;
